@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

details > summary {
    list-style: none;
}

details > summary::marker,
details > summary::-webkit-details-marker {
    display: none;
}

@layer components {
    body {
        @apply bg-rose-900
    }

    .my-focus {
        @apply border focus:border-sky-500 focus:ring-sky-500
    }

    .my-hover {
        @apply hover:brightness-110 ease-in duration-100
    }

    .my-input {
        @apply
            placeholder:italic placeholder:text-slate-400
            bg-white border-slate-300
            py-2 pl-3 pr-3 shadow-sm focus:outline-none
            focus:ring-1 sm:text-sm
        ;
    }

    .my-h1 {
        @apply text-white font-sans font-bold text-2xl
    }

    .scrollbar-red::-webkit-scrollbar {
        width: 12px;
    }

    .scrollbar-red::-webkit-scrollbar-track {
        @apply bg-red-800
    }

    .scrollbar-red::-webkit-scrollbar-thumb {
        @apply bg-red-900 rounded-[100vh] hover:bg-red-950
    }

    .scrollbar-latex::-webkit-scrollbar {
        height: 10px;
    }

    .scrollbar-latex::-webkit-scrollbar-track {
        @apply bg-red-800 rounded-md
    }

    .scrollbar-latex::-webkit-scrollbar-thumb {
        @apply bg-red-900 rounded-[100vh] hover:bg-red-950
    }

    .scrollbar-gray::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    .scrollbar-gray::-webkit-scrollbar-track {
        @apply bg-gray-100
    }

    .scrollbar-gray::-webkit-scrollbar-thumb {
        @apply bg-gray-400 rounded-[100vh] hover:bg-gray-500
    }
}